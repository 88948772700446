import Loading from "./components/Loading";
import Loadable from "react-loadable";

export const MobileBanner = Loadable({
  loader: () => import("./components/MobileBanner"),
  loading: Loading
});

export const VideoLoop = Loadable({
  loader: () => import("./components/VideoLoop"),
  loading: Loading
});

export const BuyNowLinks = Loadable({
  loader: () => import("./components/BuyNowLinks"),
  loading: Loading
});

export const SocialLinks = Loadable({
  loader: () => import("./components/SocialLinks"),
  loading: Loading
});

export const NewsFeed = Loadable({
  loader: () => import("./components/NewsFeed"),
  loading: Loading
});

export const CardSlot1 = Loadable({
  loader: () => import("./components/CardSlot1"),
  loading: Loading
});

export const Trailer = Loadable({
  loader: () => import("./components/Trailer"),
  loading: Loading
});

export const CardSlot2 = Loadable({
  loader: () => import("./components/CardSlot2"),
  loading: Loading
});

export const Features = Loadable({
  loader: () => import("./components/Features"),
  loading: Loading
});

export const Squares = Loadable({
  loader: () => import("./components/Squares"),
  loading: Loading
});

export const Carousel = Loadable({
  loader: () => import("./components/Carousel"),
  loading: Loading
});

export const Updates = Loadable({
  loader: () => import("./components/Updates"),
  loading: Loading
});

export const Footer = Loadable({
  loader: () => import("./components/Footer"),
  loading: Loading
});
