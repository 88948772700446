import React, { Component } from "react";
import Media from "react-media";
import ReactGA from "react-ga";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { Button, Modal, Form, Input, Message } from "semantic-ui-react";
import axios from "axios";
import ToTop from "react-scroll-top";
import styled from "styled-components";
import "react-animated-slider/build/horizontal.css";
import "./slider-animations.css";
import "./styles.css";

import hans from "./library/images/hans-age-gate-background.jpg";

import {
  MobileBanner,
  VideoLoop,
  BuyNowLinks,
  SocialLinks,
  NewsFeed,
  CardSlot1,
  Trailer,
  CardSlot2,
  Features,
  Squares,
  Carousel,
  Updates,
  Footer,
} from "./LazyLoading";

ReactGA.initialize("UA-384258-9");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;

    this.state = {
      day: "",
      month: "",
      year: "",
      age: cookies.get("age") || 0,
      message: "Please enter a valid Date of Birth",
      open: true,
      closeOnEscape: false,
      closeOnDimmerClick: false,
      assets: {},
      mainReviewLogo: "",
      mainReviewLogoSize: 0,
      mainReviewText: "",
      trailerLink: "",
      springUpdateScreenshot: "",
      summerUpdateScreenshot: "",
      halloweenUpdateScreenshot: "",
      christmasUpdateScreenshot: "",
      featuresSeasonalUpdateImage: "",
      currentSteamPrice: "",
      currentSteamDiscount: 0,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit() {
    if (this.state.day < 1 || this.state.day > 31) {
      this.setState({ message: "Please enter a valid day" });
    } else if (this.state.month < 1 || this.state.month > 12) {
      this.setState({ message: "Please enter a valid month" });
    } else if (this.state.year < 1900 || this.state.year > 2019) {
      this.setState({ message: "Please enter a valid year" });
    } else {
      const dob = this.state.year + this.state.month + this.state.day;
      const year = Number(dob.substr(0, 4));
      const month = Number(dob.substr(4, 2)) - 1;
      const day = Number(dob.substr(6, 2));
      const today = new Date();
      let age = today.getFullYear() - year;

      if (
        today.getMonth() < month ||
        (today.getMonth() === month && today.getDate() < day)
      ) {
        age--;
      }

      const { cookies } = this.props;
      cookies.set("age", age, { path: "/" });
      this.setState({ age });
    }
  }

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true });
  };

  close = () => this.setState({ open: false });

  componentDidMount() {
    this.getAllAssets();
  }

  getAllAssets = async () => {
    try {
      const res = await axios.get(
        "https://api.cosmicjs.com/v3/buckets/tripwireinteractivecom-tripwire-company-site/objects/64e76f5a513ef413daf66130?read_key=6g2KCi7qzTuEkWMEQB6ZDP5v7ZhY7STGFpUPn6lkBpiFEpnqyw&depth=1&props="
      );
      this.setState({
        assets: res.data.object.metadata,
      });

      const mainReviewLogo = this.state.assets.main_review_logo_png.imgix_url;
      const mainReviewLogoSize = this.state.assets.main_review_logo_size;
      const mainReviewText = this.state.assets.main_review_text;
      const trailerLink = this.state.assets.trailer_link;
      const springUpdateScreenshot = this.state.assets.spring_update_screenshot
        .imgix_url;
      const summerUpdateScreenshot = this.state.assets.summer_update_screenshot
        .imgix_url;
      const halloweenUpdateScreenshot = this.state.assets
        .halloween_update_screenshot.imgix_url;
      const christmasUpdateScreenshot = this.state.assets
        .christmas_update_screenshot.imgix_url;
      const featuresSeasonalUpdateImage = this.state.assets
        .seasonal_update_feature_image.imgix_url;
      this.setState({
        mainReviewLogo,
        mainReviewLogoSize,
        mainReviewText,
        trailerLink,
        springUpdateScreenshot,
        summerUpdateScreenshot,
        halloweenUpdateScreenshot,
        christmasUpdateScreenshot,
        featuresSeasonalUpdateImage,
      });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { open, closeOnEscape, closeOnDimmerClick } = this.state;
    const validAge = this.state.age !== 0 && this.state.age >= 17;
    const modal =
      this.state.age === 0 ? (
        <div>
          <Modal
            basic
            size="large"
            style={{
              textAlign: "center",
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "5px",
            }}
            open={open}
            closeOnEscape={closeOnEscape}
            closeOnDimmerClick={closeOnDimmerClick}
            onClose={this.close}
          >
            <Modal.Header style={{ border: "none" }}>
              Warning: Restricted Content
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required>
                    <label style={{ color: "white" }}>Day</label>
                    <Input
                      placeholder="DD"
                      name="day"
                      type="number"
                      size="large"
                      value={this.state.day}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label style={{ color: "white" }}>Month</label>
                    <Input
                      placeholder="MM"
                      name="month"
                      type="number"
                      size="large"
                      value={this.state.month}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label style={{ color: "white" }}>Year</label>
                    <Input
                      placeholder="YYYY"
                      name="year"
                      type="number"
                      size="large"
                      value={this.state.year}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <Message
                style={{
                  color: "white",
                  textAlign: "center",
                  background: "rgba(0, 0, 0, 0)",
                }}
              >
                <p>{this.state.message}</p>
              </Message>
            </Modal.Content>
            <Modal.Actions
              style={{
                display: "flex",
                justifyContent: "center",
                border: "none",
              }}
            >
              <Button
                onClick={this.handleSubmit}
                positive
                labelPosition="right"
                icon="checkmark"
                content="Check Age"
              />
            </Modal.Actions>
          </Modal>
          <ModalWrapper />
        </div>
      ) : (
        <div>
          <Modal
            basic
            size="large"
            style={{
              textAlign: "center",
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "5px",
            }}
            open={open}
            closeOnEscape={closeOnEscape}
            closeOnDimmerClick={closeOnDimmerClick}
            onClose={this.close}
          >
            <Modal.Header style={{ border: "none" }}>
              Restricted Content
            </Modal.Header>
            <Modal.Content>
              <Message
                style={{
                  color: "white",
                  textAlign: "center",
                  background: "rgba(0, 0, 0, 0)",
                }}
              >
                <p>Sorry, you do not have access to this content</p>
              </Message>
            </Modal.Content>
          </Modal>
          <ModalWrapper />
        </div>
      );

    return (
      <div>
        {validAge ? (
          <React.Fragment>
            <Media query="(max-width: 1024px)">
              {(matches) =>
                matches ? (
                  <MobileBanner />
                ) : (
                  <VideoLoop
                    mainReviewLogo={this.state.mainReviewLogo}
                    mainReviewLogoSize={this.state.mainReviewLogoSize}
                    mainReviewText={this.state.mainReviewText}
                  />
                )
              }
            </Media>
            <BuyNowLinks />
            <SocialLinks />
            <ButtonWrapper>
              <div className="inner">
                <a
                  href="https://forums.tripwireinteractive.com/forum/killing-floor-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    style={{
                      color: "white",
                      background: "black",
                      textTransform: "none",
                    }}
                  >
                    KF2 Forums
                  </button>
                </a>
              </div>
            </ButtonWrapper>
            <HeadingWrapper>
              <h1>Killing Floor 2 News</h1>
            </HeadingWrapper>
            <NewsFeed />
            <CardSlot1 />
            <HeadingWrapper style={{ marginTop: "40px" }}>
              <h1>About Killing Floor 2</h1>
            </HeadingWrapper>
            <TextWrapper>
              <p>
                In KILLING FLOOR 2, players descend into continental Europe
                after it has been overrun by horrific, murderous clones called
                Zeds that were created by the corporation Horzine. The Zed
                outbreak caused by Horzine Biotech’s failed experiments has
                quickly spread with unstoppable momentum, paralyzing the
                European Union. Only a month ago, the first Zed outbreak from
                the original KILLING FLOOR ripped through London; now the
                specimen clones are everywhere.
              </p>
            </TextWrapper>
            <Trailer trailerLink={this.state.trailerLink} />
            <TextWrapper>
              <p>
                Civilization is in disarray, communication networks have failed,
                governments have collapsed, and military forces have been
                systematically eradicated. The people of Europe are now focused
                on self-preservation, the lucky few who survived having gone
                into hiding. But not all have given up hope; a motley group of
                civilians, soldiers, and mercenaries have banded together to
                fight the outbreaks from privately funded bases across Europe.
                When a specimen clone outbreak is detected, the players are sent
                into Zed-laden hot zones to exterminate all threats using
                whatever means necessary. Welcome to the Killing Floor.
              </p>
              <p>
                New updates have been added since the original release. For more
                details visit the{" "}
                <a href="https://store.steampowered.com/app/232090/Killing_Floor_2/">
                  {" "}
                  official Steam page
                </a>
                .
              </p>
            </TextWrapper>
            <CardSlot2
              springUpdateScreenshot={this.state.springUpdateScreenshot}
              summerUpdateScreenshot={this.state.summerUpdateScreenshot}
              halloweenUpdateScreenshot={this.state.halloweenUpdateScreenshot}
              christmasUpdateScreenshot={this.state.christmasUpdateScreenshot}
            />
            <HeadingWrapper style={{ margin: "60px 0 50px 0" }}>
              <h1>Game Features</h1>
            </HeadingWrapper>
            <Features
              featuresSeasonalUpdateImage={
                this.state.featuresSeasonalUpdateImage
              }
            />
            <Squares />
            <HeadingWrapper style={{ margin: "60px 0 50px 0" }}>
              <h1>What The Critics Are Saying</h1>
            </HeadingWrapper>
            <Carousel />
            <HeadingWrapper style={{ margin: "60px 0 50px 0" }}>
              <h1>Free Updates</h1>
            </HeadingWrapper>
            <Updates />
            <ExtraPadding />
            <Footer />
            <ToTop hideAt={160} position="top" />
          </React.Fragment>
        ) : (
          modal
        )}
      </div>
    );
  }
}

export default withCookies(App);

const ModalWrapper = styled.div`
  height: 100vh;
  background-image: url(${hans});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 5px;
  padding-bottom: 40px;

  button {
    border: solid;
    border-width: 1px;
    border-color: #fde704;
    box-shadow: 0 5px 5px -3px black;
  }

  button:hover {
    background: #fde704 !important;
    color: black !important;
    cursor: pointer;
  }
`;

const HeadingWrapper = styled.div`
  color: #fde704;
  text-align: center;
  margin: 0 auto;
`;

const TextWrapper = styled.div`
  margin: 40px 0;
  padding: 0 20vw;

  span {
    font-weight: bolder;
  }

  h2 {
    font-family: "Titillium Web", sans-serif;
    font-size: 24px;
    letter-spacing: 3px;
    text-align: center;
    color: #fde704;
    text-shadow: 1px 1px #000000;
    padding: 10px 0;
  }

  a {
    color: #fde704;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  ul {
    padding-left: 15px;
  }

  p,
  li {
    text-shadow: 1px 1px black;
    font-size: 16px;
    color: white;
    text-align: justify;
  }

  li {
    margin: 5px 0;
  }

  @media (max-width: 1608px) {
    padding: 0 15vw;
  }

  @media (max-width: 1378px) {
    padding: 0 10vw;
  }

  @media (max-width: 650px) {
    margin-bottom: 20px;
    padding: 0 5vw;

    h2 {
      font-size: 21px;
      letter-spacing: 2px;
    }

    p,
    li {
      text-align: left;
      font-size: 14px;
    }
  }
`;

const ExtraPadding = styled.div`
  height: 7vh;
`;
