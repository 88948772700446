import React from "react";
import App from "./App";
import ReactGA from "react-ga";
import { CookiesProvider } from "react-cookie";
import styled from "styled-components";

ReactGA.initialize("UA-384258-9");
ReactGA.pageview(window.location.pathname + window.location.search);

export default function Root() {
  return (
    <WideScreenStyle>
      <PageWrapper>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </PageWrapper>
    </WideScreenStyle>
  );
}

const WideScreenStyle = styled.div`
  background: #1f1f1f;
`;

const PageWrapper = styled.div`
  max-width: 1920px;
  margin: auto;

  button {
    border: solid;
    border-width: 1px;
    border-color: #fde704;
    box-shadow: 0 5px 5px -3px black;
    cursor: pointer;
  }

  h1 {
    letter-spacing: 4px;
    padding: 0 10px;
    font-family: "BICUBIK", sans-serif;
    text-shadow: 2px 2px black;
  }

  h2 {
    font-family: "Titillium Web", sans-serif;
    letter-spacing: 2px;
    color: #fde704;
  }

  a,
  p {
    font-family: "Titillium Web", sans-serif;
    letter-spacing: 1px;
  }
`;
