import React from "react";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";

export default () => (
  <div>
    <Segment style={{ padding: "25vh 0" }}>
      <Dimmer active>
        <Loader>Looking out for Zeds...</Loader>
      </Dimmer>
      <Image src="/images/wireframe/short-paragraph.png" />
    </Segment>
  </div>
);
